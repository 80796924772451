import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/atoms/NavigationMenu/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/layouts/Header/Buttons/AuthButtonGroup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/layouts/Header/Buttons/CartButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/layouts/Header/Buttons/DeliveryButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/layouts/Header/Buttons/SearchButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/layouts/Header/ShopFlyoutMenu/ShopFlyoutMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/layouts/LanguageSwitcher/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/organisms/MyNavSidebar/MyNavSidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/components/organisms/NavSidebar/NavSidebar.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/images/logo-white-small.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/efarmz-website-next/efarmz-website-next/src/images/logo-white.svg");
